.mb0 {
  margin-bottom: 0px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.mb60 {
  margin-bottom: 60px !important;
}

.mb70 {
  margin-bottom: 70px !important;
}

.mb80 {
  margin-bottom: 80px !important;
}

.mb90 {
  margin-bottom: 90px !important;
}

.mb100 {
  margin-bottom: 100px !important;
}

.mt0 {
  margin-top: 0px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mt60 {
  margin-top: 60px !important;
}

.mt70 {
  margin-top: 70px !important;
}

.mt80 {
  margin-top: 80px !important;
}

.mt90 {
  margin-top: 90px !important;
}

.mt100 {
  margin-top: 100px !important;
}

.pb0 {
  padding-bottom: 0 !important;
}

.text-black {
  color: #000000 !important;
}

@media screen and (max-width: 768px) {
  .img-sm-responsive {
    max-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .img-xs-responsive {
    max-width: 100%;
  }
}

.hs-input {
  padding: 5px 10px;
}

textarea.hs-input {
  padding: 10px 20px;
}

.gsa-certified {
  &-image { }

  &-list { }

  &-text { }

  &-list,
  &-text,
  &-link {
    font-size: 16px;
  }

  &-link {
    color: #a00;
    display: inline-block;
    font-weight: bold;
    line-height: 1;
    position: relative;
    padding-right: (25px + 5px); // size of the arrow + spacer.

    &::after {
      color: #a00;
      content: "→";
      font-size: 18px;
      font-weight: bold;
      height: 18px;
      position: absolute;
      right: 0;
      top: 0;
      width: 25px;
    }
  }
}