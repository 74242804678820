/* FONT IMPORTTS */
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700');
@import "../fonts/icomoon/style.css";

/* pre-vendor imports */
@import "base/variables";
@import "base/mixins";

/* VENDOR IMPORTS */
@import "vendor/bootstrap.min.css";
@import "vendor/animate.css";
@import "vendor/probootstrap.scss";

/* APP STYLES */
@import "components/classes";
@import "components/header";
