/*!==============================
 * Theme Name: Frame
 * Theme URL: https://probootstrap.com/frame-free-onepage-html5-template-using-bootstrap
 * Author: ProBootstrap.com
 * Author URL: https://probootstrap.com
 * License: Released for free under the Creative Commons Attribution 3.0 license (probootstrap.com/license)
 */

html {
  overflow-x: hidden;
  font-size: 1.25em;
}

body {
  background: #ffffff;
  color: #000;
  line-height: 1.7;
  font-size: 18px;
  font-family: "Open Sans", Arial, sans-serif;
}

.hs-form {
    font-size: 14px;
}

.hs-form ul {
    list-style: none;
    padding-left: 10px;
}

.hs-input {
    padding-right: 10px;
}

.inputs-list {
    padding: 0px;
}

.hs-form-field {
    padding-bottom: 10px;
}

.hs-fieldtype-booleancheckbox {
    padding: 0px !important;
}

.hs-button {
    border: 2px solid #fff;
    background-color: #2C215A;
    padding: 5px 30px;
    font-size: 16px;
    color: #fff;
}

.hs-button:hover {
    background-color: #f78e1e;
    border: 2px solid #f78e1e;
    color: #FFF;
}

.hs-error-msgs {
    font-size: 12px;
    color: #aa0000;
    font-weight: 400;
}

::-moz-selection {
  background: #000000;
  color: #ffffff;
}

::selection {
  background: #000000;
  color: #ffffff;
}

::-moz-selection {
  background: #000000;
  color: #ffffff;
}

h1, h2, h3, h4, h5, h6, p, ol {
  margin-bottom: 30px;
}

h2 {
    line-height: 1.5;
}

h4 {
    font-size: 20px;
    font-weight: 700;
}

a {
  -webkit-transition: .3s all;
  transition: .3s all;
  color: #2C215A;
}

a:hover, a:focus, a:active {
  color: #f78e1e;
  text-decoration: none;
}

.probootstrap-navbar {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0px;
  margin-bottom: 0px;
  background: none;
  position: absolute;
  top: 20px;
  width: 100%;
  z-index: 200;
}

@media screen and (max-width: 768px) {
  .probootstrap-navbar {
    top: 0;
    background: white;
    -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  }
}

.probootstrap-navbar .navbar-toggle:focus,
.probootstrap-navbar .navbar-toggle:hover {
  background-color: transparent;
}

.probootstrap-navbar .navbar-toggle {
  border: none;
}

.probootstrap-navbar .navbar-toggle span.icon-bar {
  -webkit-transition: all 0.15s;
  transition: all 0.15s;
}

.probootstrap-navbar .navbar-toggle span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 10% 10%;
  transform-origin: 10% 10%;
}

.probootstrap-navbar .navbar-toggle span:nth-child(3) {
  opacity: 0;
}

.probootstrap-navbar .navbar-toggle span:nth-child(4) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 10% 90%;
  transform-origin: 10% 90%;
}

.probootstrap-navbar .navbar-toggle.collapsed span:nth-child(2),
.probootstrap-navbar .navbar-toggle.collapsed span:nth-child(4) {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.probootstrap-navbar .navbar-toggle.collapsed span:nth-child(3) {
  opacity: 1;
}

.probootstrap-navbar .parent-nav-link-padding, .probootstrap-navbar .navbar-brand, .probootstrap-navbar .navbar-nav > li > a {
  padding-left: 0;
  padding-right: 0;
  margin-left: 15px;
  margin-right: 15px;
}

.probootstrap-navbar .dropdown-header {
  color: rgba(255, 255, 255, 0.2);
  padding-left: 10px;
  text-transform: uppercase;
}

.probootstrap-navbar .dropdown > a {
  padding-right: 10px !important;
  position: relative;
  display: block;
}

@media screen and (max-width: 480px) {
  .probootstrap-navbar .dropdown > a {
    display: block;
    padding-right: 0px;
  }
}

.probootstrap-navbar .dropdown > a:before {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: -10px;
  margin-top: -11px;
  content: "\e924";
  font-size: 20px;
  color: rgba(255, 255, 255, 0.5);
  -webkit-transition: .3s all;
  transition: .3s all;
}

@media screen and (max-width: 768px) {
  .probootstrap-navbar .dropdown > a:before {
    color: rgba(0, 0, 0, 0.2);
  }
}

.probootstrap-navbar .dropdown > a:hover:before {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.probootstrap-navbar .dropdown.open > a, .probootstrap-navbar .dropdown:hover > a, .probootstrap-navbar .dropdown:focus > a, .probootstrap-navbar .dropdown:active > a {
  -webkit-transition: .3s all;
  transition: .3s all;
}

.probootstrap-navbar .dropdown.open > a:before, .probootstrap-navbar .dropdown:hover > a:before, .probootstrap-navbar .dropdown:focus > a:before, .probootstrap-navbar .dropdown:active > a:before {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.probootstrap-navbar .dropdown > .dropdown-menu > li a {
  padding: 10px;
  color: rgba(255, 255, 255, 0.8);
}

.probootstrap-navbar .dropdown > .dropdown-menu > li a:hover {
  border-top: none;
  background: #ffffff;
  color: #85C8DD;
}

.probootstrap-navbar .dropdown > .dropdown-menu > li.open > a, .probootstrap-navbar .dropdown > .dropdown-menu > li:hover > a, .probootstrap-navbar .dropdown > .dropdown-menu > li:focus > a, .probootstrap-navbar .dropdown > .dropdown-menu > li:active > a {
  border-top: none;
  color: #85C8DD;
  background: #ffffff;
}

.probootstrap-navbar .dropdown > .dropdown-menu > li.dropdown-submenu.open > a, .probootstrap-navbar .dropdown > .dropdown-menu > li.dropdown-submenu:hover > a, .probootstrap-navbar .dropdown > .dropdown-menu > li.dropdown-submenu:focus > a, .probootstrap-navbar .dropdown > .dropdown-menu > li.dropdown-submenu:active > a {
  border-top: none;
}

.probootstrap-navbar .dropdown > .dropdown-menu > li.dropdown-submenu.open > a:before, .probootstrap-navbar .dropdown > .dropdown-menu > li.dropdown-submenu:hover > a:before, .probootstrap-navbar .dropdown > .dropdown-menu > li.dropdown-submenu:focus > a:before, .probootstrap-navbar .dropdown > .dropdown-menu > li.dropdown-submenu:active > a:before {
  color: #000000;
}

.probootstrap-navbar .dropdown > .dropdown-menu > li.active > a {
  color: #ffffff;
}

.probootstrap-navbar .navbar-right .dropdown-menu {
  right: auto;
}

.probootstrap-navbar .dropdown-menu {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #85C8DD;
  border-radius: 0;
  -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.15);
  min-width: 200px;
  white-space: normal;
  word-wrap: break-word;
}

.probootstrap-navbar .dropdown-menu a {
  white-space: normal;
}

@media screen and (max-width: 768px) {
  .probootstrap-navbar .dropdown-menu {
    width: 100%;
    padding: 10px 15px;
  }
  .probootstrap-navbar .dropdown-menu a {
    color: #ffffff;
  }
}

.probootstrap-navbar .navbar-brand {
  text-transform: uppercase;
  font-family: "Open Sans", Arial, sans-serif;
  color: #ffffff;
  font-size: 26px;
  font-weight: 700;
  padding-left: 15px;
}

.probootstrap-navbar .navbar-brand:hover {
  color: #ffffff;
}

@media screen and (max-width: 768px) {
  .probootstrap-navbar .navbar-brand {
    color: #2C215A;
    line-height: 0;
    padding-left: 0px;
  }
}

.probootstrap-navbar .navbar-brand em {
  font-family: "Open Sans", Arial, sans-serif;
}

.probootstrap-navbar .navbar-nav > li > a {
  color: white;
  font-size: 15px;
  position: relative;
  text-transform: uppercase;
}

@media screen and (max-width: 768px) {
  .probootstrap-navbar .navbar-nav > li > a {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    color: rgba(0, 0, 0, 0.7);
  }
}

.probootstrap-navbar .navbar-nav > li > a:hover {
  color: rgba(255, 255, 255, 0.7);
}

.probootstrap-navbar .navbar-nav > li > a:hover, .probootstrap-navbar .navbar-nav > li > a:focus, .probootstrap-navbar .navbar-nav > li > a:active {
  color: rgba(255, 255, 255, 0.7);
}

@media screen and (max-width: 768px) {
  .probootstrap-navbar .navbar-nav > li > a:hover, .probootstrap-navbar .navbar-nav > li > a:focus, .probootstrap-navbar .navbar-nav > li > a:active {
    color: rgba(0, 0, 0, 0.7);
  }
}

.probootstrap-navbar .navbar-nav > li.open > a:hover, .probootstrap-navbar .navbar-nav > li.open > a:focus, .probootstrap-navbar .navbar-nav > li.open > a {
  background: none;
}

.probootstrap-navbar .navbar-nav > li.active > a {
  background: none;
  color: #ffffff;
}

@media screen and (max-width: 768px) {
  .probootstrap-navbar .navbar-nav > li.active > a {
    color: rgba(0, 0, 0, 0.7);
  }
}

.probootstrap-navbar .navbar-nav > li.active > a:active, .probootstrap-navbar .navbar-nav > li.active > a:focus, .probootstrap-navbar .navbar-nav > li.active > a:hover {
  background: none;
  color: #ffffff;
}

@media screen and (max-width: 768px) {
  .probootstrap-navbar .navbar-nav > li.active > a:active, .probootstrap-navbar .navbar-nav > li.active > a:focus, .probootstrap-navbar .navbar-nav > li.active > a:hover {
    color: rgba(0, 0, 0, 0.7);
  }
}

.probootstrap-navbar .navbar-nav > li > .dropdown-menu:before {
  border: 1px solid #85C8DD;
  content: "";
  position: absolute;
  top: -20px;
  left: 40px;
  border-color: rgba(133, 200, 221, 0);
  border-bottom-color: #85C8DD;
  border-width: 10px;
  margin-left: -10px;
}

@media screen and (max-width: 768px) {
  .probootstrap-navbar .navbar-nav > li > .dropdown-menu:before {
    display: none;
  }
}

.probootstrap-navbar .navbar-nav .dropdown li.active > a {
  background: none;
}

.probootstrap-navbar .navbar-nav.active > a {
  color: #2C215A !important;
}

.probootstrap-navbar .dropdown-submenu {
  position: relative;
}

.probootstrap-navbar .dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -1px;
}

.probootstrap-navbar .dropdown-submenu > a {
  display: block;
}

.probootstrap-navbar .dropdown-submenu > a:before {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 10px;
  margin-top: -11px;
  content: "\e926";
  font-size: 20px;
  color: rgba(255, 255, 255, 0.5);
  -webkit-transition: .3s all;
  transition: .3s all;
}

@media screen and (max-width: 768px) {
  .probootstrap-navbar .dropdown-submenu > a:before {
    color: rgba(0, 0, 0, 0.2);
  }
}

.probootstrap-navbar .dropdown-submenu > a:hover:before {
  color: rgba(0, 0, 0, 0.3);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

@media screen and (max-width: 480px) {
  .probootstrap-navbar .dropdown-submenu:hover > .dropdown-menu {
    display: block;
  }
}
.probootstrap-hero {
  background-color: #ccc;
  background-size: cover;
  background-position: left top;
  padding: 9em 0 15em 0;
  position: relative;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .probootstrap-hero {
    height: inherit;
    padding: 10em 0 15em 0;
  }
}

.probootstrap-hero:before {
  content: "";
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.probootstrap-hero .intro-text {
  position: relative;
  padding-top: 100px;
}

@media screen and (max-width: 768px) {
  .probootstrap-hero .intro-text {
    padding-top: 50px;
  }
}

.probootstrap-hero .intro-text .probootstrap-heading {
  font-size: 54px;
  font-weight: 300;
  color: #ffffff;
  margin-top: 70px;
  margin-bottom: 40px;
  line-height: 1.3;
}

@media screen and (max-width: 768px) {
  .probootstrap-hero .intro-text .probootstrap-heading {
    font-size: 36px;
    text-align: center;
  }
}

.probootstrap-hero .intro-text .probootstrap-subheading {
  max-width: 500px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 20px;
}

.probootstrap-hero .intro-text .probootstrap-subheading.center {
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .probootstrap-hero .intro-text .probootstrap-subheading {
    max-width: 100%;
    text-align: center;
  }
}

.probootstrap-hero .intro-text .probootstrap-subheading .btn {
  padding: 20px 40px;
  border-radius: 0;
  margin-right: 10px;
  border: 3px solid #fff;
}

.probootstrap-hero .intro-text .probootstrap-subheading .btn:hover {
  background: #f78e1e;
  border-color: #f78e1e;
}

@media screen and (max-width: 480px) {
  .probootstrap-hero .intro-text .probootstrap-subheading .btn {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.probootstrap-hero .intro-text .probootstrap-subheading .btn.btn-default {
  background: none;
  border: 2px solid rgba(255, 255, 255, 0.7);
  color: #ffffff;
}

.probootstrap-hero .intro-text .probootstrap-subheading .btn.btn-default:hover, .probootstrap-hero .intro-text .probootstrap-subheading .btn.btn-default:focus, .probootstrap-hero .intro-text .probootstrap-subheading .btn.btn-default:active {
  background: rgba(255, 255, 255, 0.3);
}

.probootstrap-section {
  padding: 5em 0;
  position: relative;
}

@media screen and (max-width: 768px) {
  .probootstrap-section {
    padding: 4em 0;
  }
}

.probootstrap-section.probootstrap-bg-light {
  background: #f9f9f9;
}

.probootstrap-section.probootstrap-cta {
  background: #2C215A;
  color: #ffffff;
  padding: 3em 0;
}

.probootstrap-section.probootstrap-cta *:last-child {
  margin-bottom: 0;
}

.probootstrap-section.probootstrap-cta .probootstrap-heading {
  color: #ffffff;
  margin-bottom: 40px;
}

.probootstrap-section.probootstrap-cta .probootstrap-sub-heading {
  color: rgba(255, 255, 255, 0.7);
}

.probootstrap-footer {
  padding: 3em 0;
  position: relative;
  background: #2C215A;
  color: #ffffff;
}

.probootstrap-footer a {
  color: #f78e1e;
}

.probootstrap-footer a:hover {
  color: white;
}

.probootstrap-section-heading .probootstrap-heading {
  font-size: 50px;
  line-height: 1.45;
  position: relative;
  padding-bottom: 30px;
}

.probootstrap-section-heading .probootstrap-heading:after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 70px;
  height: 2px;
  background: #aa0000;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

@media screen and (max-width: 768px) {
  .probootstrap-section-heading .probootstrap-heading {
    font-size: 40px;
  }
}

.probootstrap-section-heading .probootstrap-heading-2 {
  line-height: 1.3;
  font-size: 36px;
}

@media screen and (max-width: 768px) {
  .probootstrap-section-heading .probootstrap-heading-2 {
    font-size: 24px;
  }
}

.probootstrap-shadow, .probootstrap-testimonial:hover, .probootstrap-testimonial:focus, .probootstrap-form {
  -webkit-box-shadow: 0 20px 45px -10px rgba(0, 0, 0, 0.15);
  box-shadow: 0 20px 45px -10px rgba(0, 0, 0, 0.15);
}

.probootstrap-social > a {
  padding: 10px;
  font-size: 30px;
}

.probootstrap-pricing {
  text-align: center;
  padding: 30px;
  margin-bottom: 30px;
  border: 1px solid #ebebeb;
}

.probootstrap-pricing h2 {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: .2em;
  color: #000000;
  margin-bottom: 0;
}

.probootstrap-pricing .probootstrap-note {
  color: #bdbdbd;
  font-style: italic;
  font-size: 14px;
}

.probootstrap-pricing .probootstrap-price strong {
  font-size: 62px;
  font-weight: 300;
  color: #2C215A;
}

@media screen and (max-width: 768px) {
  .probootstrap-pricing .probootstrap-price strong {
    font-size: 42px;
  }
}

.probootstrap-link {
  position: relative;
}

.probootstrap-link i {
  position: relative;
  right: 0;
  top: .15em;
  -webkit-transition: .3s all ease;
  transition: .3s all ease;
}

.probootstrap-link:hover i {
  right: -10px;
}

.probootstrap-list {
  padding: 0;
  margin: 0;
}

.probootstrap-list li {
  margin: 0 0 10px 0;
  padding: 0;
  position: relative;
  list-style: none;
}

.probootstrap-list li:before {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: .3em;
  left: 0;
}

.probootstrap-list li.probootstrap-check {
  padding-left: 30px;
}

.probootstrap-list li.probootstrap-check:before {
  color: #2C215A;
  font-size: 16px;
  content: "\eb91";
}

.probootstrap-check-2 {
  padding-left: 40px;
  position: relative;
}

.probootstrap-check-2:before {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: .2em;
  left: 0;
  color: #2C215A;
  font-size: 20px;
  content: "\eb91";
}

.btn {
  border-radius: 0;
  font-size: 16px;
  padding: 10px 40px;
}

.btn.btn-primary {
  background: #2C215A;
}

.btn.btn-primary:hover, .btn.btn-primary:active, .btn.btn-primary:focus {
  background: #103463;
}

.btn.btn-black {
  color: #ffffff;
  background: #000000;
}

.btn.btn-black:hover, .btn.btn-black:active, .btn.btn-black:focus {
  background: #262626;
}

.probootstrap-service-item {
  position: relative;
  padding: 30px;
  margin-bottom: 0px;
  float: left;
  width: 100%;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .probootstrap-service-item {
    padding: 0;
  }
}

.probootstrap-service-item .icon {
  display: block;
  font-size: 70px;
  margin-bottom: 50px;
  color: #aa0000;
}

.probootstrap-service-item h2 {
  font-size: 24px;
  color: #000;
  text-align: center;
}

.probootstrap-testimonial {
  background: #ffffff;
  padding: 30px;
  -webkit-transition: .3s all ease;
  transition: .3s all ease;
  border-radius: 4px;
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .probootstrap-testimonial {
    padding: 20px;
  }
}

.probootstrap-testimonial .probootstrap-author-photo {
  height: 70px;
}

.probootstrap-testimonial .probootstrap-rate i {
  color: #d9d9d9;
}

.probootstrap-testimonial .probootstrap-rate i.icon-star {
  color: #F79F24;
}

.probootstrap-testimonial blockquote {
  padding: 0;
  border: none;
}

.probootstrap-testimonial blockquote p {
  font-style: italic;
  color: #000000;
}

.probootstrap-form {
  margin-bottom: 0px;
  padding: 40px;
  background: #ffffff;
  margin-bottom: 50px;
}

@media screen and (max-width: 768px) {
  .probootstrap-form {
    padding: 15px;
  }
}

.probootstrap-form label {
  font-weight: normal;
}

.probootstrap-form .form-control {
  height: 46px;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  border: 1px solid #d9d9d9;
}

.probootstrap-form .form-control:active, .probootstrap-form .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #2C215A;
}

.probootstrap-form .form-group:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.probootstrap-form .form-group label {
  text-transform: uppercase;
  font-size: 13px;
}

.probootstrap-form .form-group .form-field {
  position: relative;
}

.probootstrap-form .form-group .form-field .icon {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 20px;
}

.probootstrap-form .form-group .form-field .form-control {
  padding-right: 50px;
}

.probootstrap-form textarea.form-control {
  height: inherit;
  resize: vertical;
}

.probootstrap-contact-details {
  padding: 0;
  margin: 0;
}

.probootstrap-contact-details li {
  padding: 0;
  margin: 0 0 30px 0;
  list-style: none;
}

.probootstrap-contact-details li > span {
  display: block;
  color: rgba(0, 0, 0, 0.3);
}

.probootstrap-animate {
  opacity: 0;
  visibility: hidden;
}

.mfp-fade.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}

.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

.image-link {
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

/* This block of CSS adds opacity transition to background */
.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}

/* padding-bottom and top for image */
.mfp-no-margins img.mfp-img {
  padding: 0;
}

/* position of shadow behind the image */
.mfp-no-margins .mfp-figure:after {
  top: 0;
  bottom: 0;
}

/* padding for main container */
.mfp-no-margins .mfp-container {
  padding: 0;
}

/* aligns caption to center */
.mfp-title {
  text-align: center;
  padding: 6px 0;
}

.image-source-link {
  color: #DDD;
}

.probootstrap-gutter0 > div[class*='col-'] {
  padding-right: 0px;
  padding-left: 0px;
}

@media screen and (max-width: 480px) {
  .probootstrap-gutter0 > div[class*='col-'] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.probootstrap-gutter10 > div[class*='col-'] {
  padding-right: 5px !important;
  padding-left: 5px !important;
}

@media screen and (max-width: 480px) {
  .probootstrap-gutter10 > div[class*='col-'] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.probootstrap-gutter30 > div[class*='col-'] {
  padding-right: 15px;
  padding-left: 15px;
}

@media screen and (max-width: 480px) {
  .probootstrap-gutter30 > div[class*='col-'] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.probootstrap-gutter40 > div[class*='col-'] {
  padding-right: 20px;
  padding-left: 20px;
}

@media screen and (max-width: 480px) {
  .probootstrap-gutter40 > div[class*='col-'] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.probootstrap-gutter60 > div[class*='col-'] {
  padding-right: 30px;
  padding-left: 30px;
}

@media screen and (max-width: 480px) {
  .probootstrap-gutter60 > div[class*='col-'] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.mb0 {
  margin-bottom: 0px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.mb60 {
  margin-bottom: 60px !important;
}

.mb70 {
  margin-bottom: 70px !important;
}

.mb80 {
  margin-bottom: 80px !important;
}

.mb90 {
  margin-bottom: 90px !important;
}

.mb100 {
  margin-bottom: 100px !important;
}

.mt0 {
  margin-top: 0px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mt60 {
  margin-top: 60px !important;
}

.mt70 {
  margin-top: 70px !important;
}

.mt80 {
  margin-top: 80px !important;
}

.mt90 {
  margin-top: 90px !important;
}

.mt100 {
  margin-top: 100px !important;
}

.pb0 {
  padding-bottom: 0 !important;
}

.text-black {
  color: #000000 !important;
}

.text-primary {
  color: #aa0000 !important;
}

@media screen and (max-width: 768px) {
  .img-sm-responsive {
    max-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .img-xs-responsive {
    max-width: 100%;
  }
}

.center {
    text-align: center;
        margin: auto;
}

